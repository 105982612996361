import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    name: { default: "", type: String },
    resourceType: { default: "", type: String },
    resourceId: { default: 0, type: Number },
    data: { default: "{}", type: String },
  };

  connect() {
    this.element.addEventListener("click", (ev) => {
      this.trackTimelineEvent();
    });
  }

  trackTimelineEvent() {
    const url = "/timeline_events"
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCsrfToken(),
      },
      body: JSON.stringify({
        timeline_event: {
          name: this.nameValue,
          resource_type: this.resourceTypeValue,
          resource_id: this.resourceIdValue,
          data: this.dataValue
        },
      }),
    });
  }

  getCsrfToken() {
    return document.querySelector("[name='csrf-token']").content;
  }
}
