import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable"];
  static values = {
    open: { type: Boolean, default: false },
  };

  toggle(event) {
    this.openValue = event.target.checked;
    this.animate();
  }

  animate() {
    this.toggleableTargets.forEach((target) => {
      this.transition(target, this.openValue);
    });
  }

  transition(element, state) {
    if (!!state) {
      element.classList.remove("hidden");
    } else {
      element.classList.add("hidden");
    }
  }
}
